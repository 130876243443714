import ImageResize from 'quill-image-resize-module-react'; // Import the image resize module
import { Quill } from 'react-quill';

export const rewardType = ['Currency', 'Percent', 'Text'];
export const rewardTypeAr = ['عملة', 'نسبه مئويه', 'نص'];
export const cashbackRewardType = ['Currency', 'Percent'];
export const cashbackRewardTypeAr = ['عملة', 'نسبه مئويه'];
export const bannerPages = {
	1: 'Home',
	2: 'Category',
	3: 'Brand',
	5: 'Offers Page',
	6: 'Coupons Page',
	7: 'Offers Listing',
	8: 'Coupons Listing',
};
export const bannerSubPages = {
	1: 'Offers',
	2: 'Coupons',
};
export const Status = [
	{
		name: 'Active',
		value: 1,
	},
	{
		name: 'Inactive',
		value: 0,
	},
];

export const disablefilter = {
	sortable: false,
	filterable: false,
	hide: false,
	headerClassName: 'datagrid-header',
};

export const appConfig = {
	google: {
		maps: {
			apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		},
	},
	// captcha: {
	// 	siteKey: process.env.REACT_APP_CAPTCHA_KEY,
	// },
};

// routes to be sent as link internal notifications for push notifications
export const webpageRoutes = [
	{
		label: 'Home',
		value: '/',
	},
	{
		label: 'Brand',
		value: '/discount-codes/',
	},
	{
		label: 'Category',
		value: '/all-category/',
	},
	{
		label: 'Privacy Policy',
		value: '/policy/',
	},
	{
		label: 'FAQ',
		value: '/faq/',
	},
	{
		label: 'About Us',
		value: '/about-us/',
	},
	{
		label: 'Advertise',
		value: '/advertise-with-us/',
	},
	{
		label: 'Terms and Conditions',
		value: '/terms-and-conditions/',
	},
	{
		label: 'Contact Us',
		value: '/contact-us/',
	},
	{
		label: 'Sign in',
		value: '/login/',
	},
	{
		label: 'Sign up',
		value: '/signup/',
	},
];
export const PerPageCount = [15, 20, 40, 50, 100];

export const offersSubPageCode = 1;
export const couponsSubPageCode = 2;
export const bannerCategoryCode = 2;
export const bannerBrandCode = 3;
export const offersListingPageCode = 7;
export const couponsListingPageCode = 8;
export const couponsCode = 6;
export const offersCode = 5;
export const langCodeEng = 'en';
export const langCodeAra = 'ara';

// Register the image resize module with Quill
Quill.register('modules/imageResize', ImageResize);

export const reactQuillModules = {
	toolbar: [
		[{ header: [1, 2, 3] }, { font: [] }],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['bold', 'italic', 'underline'],
		['link'],
		['image'],
	],
	imageResize: {
		parchment: Quill.import('parchment'),
		modules: ['Resize', 'DisplaySize'],
	},
};
